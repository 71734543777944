import { Component, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IdPerfil, Role } from 'src/app/_models/enums';
import { ManageUsersService } from '../manage-users.service';
import { UserDetailsModel } from '../DTO/userDetailsModel';
import { SelectOption } from 'src/app/_models/select-option';
import { GetEnumKeyByEnumValue } from 'src/app/_helpers/enums.helper';
import { isNullOrUndefined } from 'util';
import { ToastrService } from 'ngx-toastr';
import { isNullOrEmpty } from '../../_helpers/string.helper';
import { DatePipe } from '@angular/common';
import { LoginService } from '../../login/login.service';

@Injectable({
	providedIn: 'root',
})

@Component({
	selector: 'app-manageUsersDetails',
	templateUrl: './manageUsersDetails.component.html',
	styleUrls: ['./manageUsersDetails.component.scss',]
})

export class ManageUsersDetailsComponent implements OnInit {
	@Input() _manageUsersForm: FormGroup;

	usuarioDescriptionPattern = /^([a-z0-9]+[.]*)*[a-z0-9]+$/i;
	emailDescriptionPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
	EMAIL_MAX_LENGTH = 100;
	EMAIL_MIN_LENGTH = 5;

	_profiles: SelectOption[];
	IdPerfil: IdPerfil;

	constructor(
		private manageUsersService: ManageUsersService,
		private ngxLoader: NgxUiLoaderService,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private datePipe: DatePipe,
		private loginService: LoginService
	) { }

	ngOnInit() {
		this.CreteForm();
		this.GetPerfis();
		this.IdPerfil = this.loginService.user.idPerfil;
	}

	GetPerfis() {
		this.ngxLoader.start();
		this.manageUsersService.GetPerfil().subscribe((d: any) => {
			this.ngxLoader.stop();
			this._profiles = d.registro;
		});
	}

	CreteForm() {
		this._manageUsersForm = this.formBuilder.group({
			nomeCompleto: this.formBuilder.control(null, [Validators.required, Validators.minLength(5), Validators.maxLength(30)]),
			email: this.formBuilder.control(null, [Validators.required, Validators.minLength(this.EMAIL_MIN_LENGTH), Validators.maxLength(this.EMAIL_MAX_LENGTH), Validators.pattern(this.emailDescriptionPattern)]),
			usuario: this.formBuilder.control(null, [Validators.required, Validators.minLength(5), Validators.maxLength(30), Validators.pattern(this.usuarioDescriptionPattern)]),
			idPerfil: this.formBuilder.control(null, [Validators.required]),
			ativo: this.formBuilder.control(null),
			alterarSenha: this.formBuilder.control(null),
			dataCriacao: this.formBuilder.control(null),
			ultimaAtualizacao: this.formBuilder.control(null),
		});
		this._manageUsersForm.controls.usuario.disable();
		this._manageUsersForm.controls.dataCriacao.disable();
		this._manageUsersForm.controls.ultimaAtualizacao.disable();
	}

	GetEnumidPerfil() {
		let array: SelectOption[] = [];
		Object.keys(IdPerfil).forEach((element) => {
			if (isNaN(Number(element)) === false) {
				var key = GetEnumKeyByEnumValue(IdPerfil, element);
				let profile: SelectOption = { value: element, label: key };
				if ((parseInt(profile.value) > 0) && (parseInt(profile.value) < 8)) {
					array.push(profile);
				}
			}
		})
		return array;
	}

	RefreshModalContent(dataSource: UserDetailsModel) {

		this._manageUsersForm.reset();

		if (isNullOrUndefined(dataSource)) {
			this.EnableFormFields()
			this._manageUsersForm.controls.usuario.enable();
			this._manageUsersForm.controls.email.enable();
			this._manageUsersForm.controls.ativo.setValue(true);
		}
		else {
			this._manageUsersForm.controls.email.disable();

			if (this.manageUsersService.GetPageMode() === 'query') {
				this.DisableFormFields()
			}
			else {
				this.EnableFormFields()
			}

			this._manageUsersForm.setValue(dataSource);

			let selectedProfile: SelectOption = this._profiles.find(x => x.id == dataSource.idPerfil.toString()) || {};
			if (selectedProfile.id) {
				this._manageUsersForm.controls.idPerfil.setValue(selectedProfile.id.toString());
			}

			this._manageUsersForm.controls.dataCriacao.setValue(this.datePipe.transform(this._manageUsersForm.controls.dataCriacao.value, "dd/MM/yyyy hh:MM:ss"));

			this._manageUsersForm.controls.ultimaAtualizacao.setValue(this.datePipe.transform(this._manageUsersForm.controls.ultimaAtualizacao.value, "dd/MM/yyyy hh:MM:ss"));
			this.HasRoleEditUser();
		}
	}

	DisableFormFields() {
		this._manageUsersForm.controls.nomeCompleto.disable();
		this._manageUsersForm.controls.ativo.disable();
		this._manageUsersForm.controls.alterarSenha.disable();
		this._manageUsersForm.controls.usuario.disable();
		this._manageUsersForm.controls.idPerfil.disable();
	}

	EnableFormFields() {
		this._manageUsersForm.controls.nomeCompleto.enable();
		this._manageUsersForm.controls.ativo.enable();
		this._manageUsersForm.controls.alterarSenha.enable();

		if (this.HasRoleEditUser())
			this._manageUsersForm.controls.idPerfil.enable();
		else
			this._manageUsersForm.controls.idPerfil.disable();
	}

	FinishDataClicked(): void {
		this.ngxLoader.stop();
	}

	StartDataClicked(): void {
		this.ngxLoader.start();
	}

	SetChangedField() {
		if (!this.IsValidForm()) {
			return;
		}

		let currentUser = this.manageUsersService.GetCurrentUser();
		let hasChanges = false;

		if (currentUser.nomeCompleto !== this._manageUsersForm.controls.nomeCompleto.value) {
			currentUser.nomeCompleto = this._manageUsersForm.controls.nomeCompleto.value;
			hasChanges = true;
		}

		if (currentUser.email !== this._manageUsersForm.controls.email.value) {
			currentUser.email = this._manageUsersForm.controls.email.value;
			hasChanges = true;
		}

		if ((currentUser.usuario !== this._manageUsersForm.controls.usuario.value) && this.manageUsersService.GetIsNewUser()) {
			currentUser.usuario = this._manageUsersForm.controls.usuario.value;
			hasChanges = true;
		}

		if (currentUser.idPerfil !== Number(this._manageUsersForm.controls.idPerfil.value)) {
			currentUser.idPerfil = this._manageUsersForm.controls.idPerfil.value;
			hasChanges = true;
		}

		if (currentUser.ativo !== this._manageUsersForm.controls.ativo.value) {
			currentUser.ativo = this._manageUsersForm.controls.ativo.value;
			hasChanges = true;
		}

		if (currentUser.alterarSenha !== this._manageUsersForm.controls.alterarSenha.value) {
			currentUser.alterarSenha = this._manageUsersForm.controls.alterarSenha.value;
			hasChanges = true;
		}

		if (hasChanges) {
			this.manageUsersService.SetCurrentUser(currentUser);
		}
	}

	IsValidForm(): boolean {
		if (!this._manageUsersForm.valid) {

			let field = this._manageUsersForm.controls.nomeCompleto.value;
			if (!isNullOrUndefined(field) && !isNullOrEmpty(field) && field.trim().length < 5) {
				this.toastr.info("Campo Nome Completo deve ter no mínimo 5 caracteres");
				return false;
			}

			field = this._manageUsersForm.controls.email.value;
			if (!isNullOrUndefined(field) && !isNullOrEmpty(field) && field.trim().length < 5) {
				this.toastr.info("Campo Email deve ter no mínimo 5 caracteres");
				return false;
			}

			if (this._manageUsersForm.controls.email.hasError('pattern')) {
				this.toastr.info("Email inválido");
				return false;
			}

			field = this._manageUsersForm.controls.usuario.value;
			if (!isNullOrUndefined(field) && !isNullOrEmpty(field) && field.trim().length < 5) {
				this.toastr.info("Campo Usuário deve ter no mínimo 5 caracteres");
				return false;
			}

			if (this._manageUsersForm.controls.usuario.hasError('pattern')) {
				this.toastr.info("Campo Usuário permite apenas letras minúsculas, números e ponto (.)");
				return false;
			}

			if (!this._manageUsersForm.controls.idPerfil.value) {
				this.toastr.info("Campo Perfil é obrigatório");
				return false;
			}
		}
		return true;
	}

	enableEmail() {
		this._manageUsersForm.controls.email.enable();
	}

	HasRoleEditUser() {

		if (!this.loginService.hasRole(Role.EDITAR_USUARIOS)) {
			this._manageUsersForm.controls.idPerfil.disable();
			this._manageUsersForm.controls.ativo.disable();
			return false;
		}

		return true;
	}
}
