import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { UserService } from '../user.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login/login.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss']
})
export class CompleteRegistrationComponent implements OnInit {

  completeForm: FormGroup;
  dataSource: any;
  public regimeSimplesNacional = true;

  public regimeOptions = [];
  public tributacaoOptions = [];
  public enquadramentoOptions = [];
  public atividadeOptions = [];
  public verticalsData: any[] = [];
  public verticalId: string = '';

  constructor(private formBuilder: FormBuilder,
    private userService: UserService,
    private loginService: LoginService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.completeForm = this.formBuilder.group({
      razaoSocial: new FormControl({ value: '' }),
      nomeFantasia: new FormControl({ value: '' }),
      inscricaoEstadual: new FormControl({ value: '' }),
      inscricaoMunicipal: new FormControl({ value: '' }),
      responsavel: new FormControl('', Validators.required),
      erpId: new FormControl(''),
      nomeContador: new FormControl(''),
      crcContador: new FormControl(''),
      tipoContador: new FormControl(0),
      clientId: new FormControl(''),
      tipoAtividade: new FormControl('', Validators.required),
      cpfResponsavel: new FormControl('', Validators.required),
      chaveAcesso: new FormControl('', Validators.required),
      utilizaEscrituracaoFiscal: new FormControl(''),
      telefone: new FormControl(''),
      calculationBasisId: new FormControl(''),
      taxRegimeId: new FormControl(''),
      simplesFrameworkId: new FormControl(''),
      mainActivitiesId: new FormControl(''),
      dataSimplesNacional: new FormControl(''),
      cpfCnpjContador: new FormControl(''),
      integraContador: new FormControl(false),
      cnae: new FormControl('', Validators.required),
      substitutotributarioicms: new FormControl(null, Validators.required),
      senhaCertificado: new FormControl(null, Validators.required),
      base64Certificado: new FormControl(null, Validators.required),
      // escrituraPeloErp: new FormControl(null, Validators.required)
    });
    this.ngxLoader.start();
    combineLatest([
      this.userService.GetOptionsCalculation(),
      this.userService.GetOptionsMain(),
      this.userService.GetOptionsSimples(),
      this.userService.GetOptionsTax(),
      this.userService.GetVerticals()
    ]).subscribe((data: any) => {
      this.regimeOptions = data[0];
      this.atividadeOptions = data[1];
      this.enquadramentoOptions = data[2];
      this.tributacaoOptions = data[3];
      this.verticalsData = data[4].registro;
      this.loadEmitterData();
    }, () => {
      this.ngxLoader.stop();
    });
  }

  getErpByVerticalId() {
    const vertical = this.verticalsData.find(x => x.id == this.verticalId)
    return vertical ? vertical.erps : [];
  }

  loadEmitterData() {
    this.ngxLoader.start();
    let cnpj = this.loginService.getCnpj();

    this.userService.GetCompleteEmitterRegistration(cnpj).subscribe(data => {
      this.dataSource = data;
      for (let key in data) {
        if (data[key] === null) data[key] = "";
      }
      this.completeForm.patchValue({ ...this.completeForm.getRawValue(), ...data });
      if (data.regimeTributario != 'SimplesNacional') {
        this.completeForm.get('cpfResponsavel').clearValidators();
        this.completeForm.get('cpfResponsavel').updateValueAndValidity();
        this.completeForm.get('chaveAcesso').clearValidators();
        this.completeForm.get('chaveAcesso').updateValueAndValidity();
        this.regimeSimplesNacional = false;
      }

      if (!data.HabilitaSenhaCertificado) {
        this.completeForm.get('senhaCertificado').clearValidators();
        this.completeForm.get('senhaCertificado').updateValueAndValidity();
        this.completeForm.get('base64Certificado').clearValidators();
        this.completeForm.get('base64Certificado').updateValueAndValidity();
      }

      if (data.erpId) {
        const vertical = this.verticalsData.find(vertical => vertical.erps.find(erp => erp.id == data.erpId));
        if (vertical) {
          this.verticalId = vertical.id;
          this.completeForm.get('erpId').setValue(data.erpId);
        }
      }

      this.completeForm.get('cpfCnpjContador').clearValidators();
      this.completeForm.get('cpfCnpjContador').updateValueAndValidity();

      this.completeForm.get('inscricaoEstadual').clearValidators();
      this.completeForm.get('inscricaoEstadual').updateValueAndValidity();
      this.completeForm.get('inscricaoMunicipal').clearValidators();
      this.completeForm.get('inscricaoMunicipal').updateValueAndValidity();

      this.completeForm.controls['razaoSocial'].setValue(data.razaoSocial);
      this.completeForm.controls['nomeFantasia'].setValue(data.nomeFantasia);
      this.completeForm.controls['inscricaoEstadual'].setValue(data.inscricaoEstadual);
      this.completeForm.controls['inscricaoMunicipal'].setValue(data.inscricaoMunicipal);
      this.completeForm.controls['base64Certificado'].setValue(data.base64Certificado);
      this.completeForm.controls['cnae'].setValue(data.cnae);
      this.completeForm.controls['substitutotributarioicms'].setValue(data.substitutoTributarioICMS);
      this.completeForm.controls['responsavel'].setValue(data.responsavel);

      this.completeForm.get('simplesFrameworkId').disable();
      this.completeForm.get('calculationBasisId').disable();
      this.completeForm.get('dataSimplesNacional').disable();
      this.onChangeRegime();
      this.ngxLoader.stop();
    })
  }

  onChangeRegime() {
    const regime = this.completeForm.get('taxRegimeId').value;
    if (!['d5dd8ebd-d28d-430c-8bc6-7de08eaf44e9', '9b34afd0-0018-4dc3-8111-1bc8aba30bf6', '2a866e25-13dc-4fbf-97fe-904c5b64e949'].includes(regime)) {
      this.completeForm.get('calculationBasisId').disable();
      this.completeForm.get('dataSimplesNacional').disable();
      this.completeForm.get('simplesFrameworkId').disable();
      this.completeForm.get('simplesFrameworkId').setValue(null);
      this.completeForm.get('simplesFrameworkId').clearValidators();
      this.completeForm.get('simplesFrameworkId').updateValueAndValidity();
      this.completeForm.get('chaveAcesso').disable();
      this.completeForm.get('chaveAcesso').setValue(null);
      this.completeForm.get('chaveAcesso').clearValidators();
      this.completeForm.get('chaveAcesso').updateValueAndValidity();
      this.completeForm.get('cpfResponsavel').disable();
      this.completeForm.get('cpfResponsavel').setValue(null);
      this.completeForm.get('cpfResponsavel').clearValidators();
      this.completeForm.get('cpfResponsavel').updateValueAndValidity();
      this.completeForm.get('calculationBasisId').setValue(null);
      this.completeForm.get('dataSimplesNacional').setValue(null);
      this.completeForm.get('calculationBasisId').disable();
      this.completeForm.get('dataSimplesNacional').disable();
      this.completeForm.get('calculationBasisId').clearValidators();
      this.completeForm.get('calculationBasisId').updateValueAndValidity();
      this.completeForm.get('dataSimplesNacional').clearValidators();
      this.completeForm.get('dataSimplesNacional').updateValueAndValidity();
      this.regimeSimplesNacional = false;
      return;
    }

    this.completeForm.get('dataSimplesNacional').enable();
    this.completeForm.get('simplesFrameworkId').enable();
    this.completeForm.get('simplesFrameworkId').setValidators(Validators.required);
    this.completeForm.get('simplesFrameworkId').updateValueAndValidity();
    this.completeForm.get('calculationBasisId').enable();
    this.completeForm.get('calculationBasisId').setValidators(Validators.required);
    this.completeForm.get('calculationBasisId').updateValueAndValidity();
    this.completeForm.get('chaveAcesso').enable();
    this.completeForm.get('chaveAcesso').setValidators(Validators.required);
    this.completeForm.get('chaveAcesso').updateValueAndValidity();
    this.completeForm.get('cpfResponsavel').enable();
    this.completeForm.get('cpfResponsavel').setValidators(Validators.required);
    this.completeForm.get('cpfResponsavel').updateValueAndValidity();
    this.completeForm.get('dataSimplesNacional').setValidators(Validators.required);
    this.completeForm.get('dataSimplesNacional').updateValueAndValidity();
    this.regimeSimplesNacional = true;
  }

  onChangeContador() {
    const tipoContador = this.completeForm.get('tipoContador').value;
    if (tipoContador == 0) {
      this.completeForm.get('nomeContador').setValidators(Validators.required);
      this.completeForm.get('nomeContador').updateValueAndValidity();
      this.completeForm.get('crcContador').setValidators(Validators.required);
      this.completeForm.get('crcContador').updateValueAndValidity();
      this.completeForm.get('cpfCnpjContador').setValidators(Validators.required);
      this.completeForm.get('cpfCnpjContador').updateValueAndValidity();
      this.completeForm.get('clientId').clearValidators();
      this.completeForm.get('clientId').updateValueAndValidity();
      return;
    }

    this.completeForm.get('nomeContador').clearValidators();
    this.completeForm.get('nomeContador').updateValueAndValidity();
    this.completeForm.get('crcContador').clearValidators();
    this.completeForm.get('crcContador').updateValueAndValidity();
    this.completeForm.get('cpfCnpjContador').clearValidators();
    this.completeForm.get('cpfCnpjContador').updateValueAndValidity();
    this.completeForm.get('clientId').setValidators(Validators.required);
    this.completeForm.get('clientId').updateValueAndValidity();
  }

  removeValidators() {
    const integraContador = this.completeForm.get('integraContador').value;
    if (!integraContador) {
      this.completeForm.get('nomeContador').clearValidators();
      this.completeForm.get('nomeContador').updateValueAndValidity();
      this.completeForm.get('crcContador').clearValidators();
      this.completeForm.get('crcContador').updateValueAndValidity();
      this.completeForm.get('cpfCnpjContador').clearValidators();
      this.completeForm.get('cpfCnpjContador').updateValueAndValidity();
      this.completeForm.get('clientId').clearValidators();
      this.completeForm.get('clientId').updateValueAndValidity();
      return;
    }

    this.completeForm.get('nomeContador').setValidators(Validators.required);
    this.completeForm.get('nomeContador').updateValueAndValidity();
    this.completeForm.get('crcContador').setValidators(Validators.required);
    this.completeForm.get('crcContador').updateValueAndValidity();
    this.completeForm.get('cpfCnpjContador').setValidators(Validators.required);
    this.completeForm.get('cpfCnpjContador').updateValueAndValidity();
    this.completeForm.get('clientId').setValidators(Validators.required);
    this.completeForm.get('clientId').updateValueAndValidity();
  }

  onSubmit(formData) {

    if (!formData.integraContador) {
      this.completeForm.get('cpfCnpjContador').clearValidators();
    }

    if (formData.integraContador && formData.tipoContador == 0 && !this.isCpfCnpjValid(formData.cpfCnpjContador)) {
      return this.toastr.error('Preencha o campo "CPF/CNPJ Contador" corretamente para continuar.')
    }

    if (!this.completeForm.valid) {
      return;
    }

    formData.Cnpj = this.loginService.getCnpj();

    this.ngxLoader.start();

    this.userService.PostCompleteRegistration(formData)
      .subscribe(data => {
        this.loginService.disableForceCompleRegistration();

        this.toastr.info(data.mensagem, '');
        this.ngxLoader.stop();

        if (data.sucesso) {
          this.router.navigate(["/dashboard"]);
        }
      },
        err => {
          this.toastr.error(err && err.mensagem ? err.mensagem : 'Erro ao completar cadastro, por favor tente novamente.');
          this.ngxLoader.stop();
        })
  }

  isCpfCnpjValid(cpfCnpj: string) {
    if (!cpfCnpj) return false;
    return cpfCnpj.length === 14 || cpfCnpj.length === 11;
  }

  getMask(formData) {
    return formData.cpfCnpjContador.length <= 11 ? '000.000.000-009' : '00.000.000/0000-00';
  }

  onSelectCertificate(e: any) {
    const env = this;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      env.completeForm.patchValue({
        base64Certificado: (reader.result as string).split('base64,')[1]
      });
    };
  }

  openUrl(url: string) {
    window.open(url, '_blank')
  }
}
