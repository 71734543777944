import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { LabelPositionEnum } from 'src/app/shared/grid/DTO/checkboxColumn.model';
import { GridColumn } from 'src/app/shared/grid/DTO/gridColumn.model';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { CreateGridAction, CreateNewCheckBox, CreateNewCheckBoxColumn, CreateNewGridColumn, CreateNewGridColumnCheckbox, CreateNewIcon } from 'src/app/shared/grid/grid.helper';
import { UserAccessRS } from '../DTO/UserAccessRS';
import { UserAccessDocument, UserDocument } from '../DTO/UserDocument';
import { ManageUsersService } from '../manage-users.service';
import { Role } from '../../_models/enums';

@Injectable({
	providedIn: 'root'
})

@Component({
	selector: 'app-manageUsersAccess',
	templateUrl: './manageUsersAccess.component.html',
	styleUrls: ['./manageUsersAccess.component.scss']
})
export class ManageUsersAccessComponent implements OnInit {
	userAccessGridColumns: GridColumn[] = [];
	currentDataInGrid: UserAccessDocument[];
	LoggedUserHasEditRole: any;

	@ViewChild('gridUserAccess') gridUserAccess: GridComponent;

	constructor(private manageUsersService: ManageUsersService,
		private loginService: LoginService) {

	}

	ngOnInit() {
		this.LoggedUserHasEditRole = this.loginService.hasRole(Role.EDITAR_USUARIOS);
	}

	CreateGrid() {
		this.userAccessGridColumns = [];

		var pageMode = this.manageUsersService.GetPageMode();

		if (pageMode === 'edit' && (this.LoggedUserHasEditRole)) {
			this.userAccessGridColumns.push(CreateNewGridColumnCheckbox(80, 'checkbox', '', (element: any) => `${element.checkbox}`, false, false, true, CreateNewCheckBoxColumn(false, false, LabelPositionEnum.after, false, '')));
		}

		this.userAccessGridColumns.push(CreateNewGridColumn(200, 'cnpj', 'CNPJ', (element: UserAccessDocument) => `${element.cnpj}`, false, false));
		this.userAccessGridColumns.push(CreateNewGridColumn(400, 'nomeFantasia', 'Nome Fantasia', (element: UserAccessDocument) => `${element.nomefantasia  ? element.nomefantasia : '-'}`, false, false));
		this.userAccessGridColumns.push(CreateNewGridColumn(220, 'actions', 'Padrão', (element: UserAccessDocument) => `${element.actions}`, false, true));

		this.currentDataInGrid = this.BuilGridData(pageMode);

		this.gridUserAccess.refreshDateSource(this.currentDataInGrid, this.userAccessGridColumns);
	}

	BuilGridData(pageMode: string): UserAccessDocument[] {
		var currentUser = this.manageUsersService.GetCurrentUser();

		var accessesResponse = currentUser.acessos === null ? [] : currentUser.acessos;

		var loggedUserAccesses = this.loginService.GetAccesses();

		if (pageMode === 'edit') {
			loggedUserAccesses.forEach(item => {
				console.log(item);

				item.padrao = false;
				item.nomefantasia =  item.nomefantasia ? item.nomefantasia : '-'

				if (!accessesResponse.some(x => x.cnpj === item.cnpj)) {
					accessesResponse.push(item);
				}
			});
		}

		if (accessesResponse !== null) {
			accessesResponse.forEach(item => {
				item.actions = [];

				if (item.padrao) {
					item.actions.push(CreateGridAction('padrao', null, CreateNewIcon('padrao', ''), 'icon-lx-star mat-icon icon-design', 'Padrão de acesso'));
				}

				if (pageMode === 'edit') {
					var loggedUserHasAcccessInCnpj = loggedUserAccesses.some(x => x.cnpj === item.cnpj);
					var currentUserHasAccessInCnpj = currentUser && currentUser.acessos ? currentUser.acessos.some(x => x.cnpj === item.cnpj) : false;

					item.checkbox = CreateNewCheckBox(currentUserHasAccessInCnpj, false, LabelPositionEnum.after, !loggedUserHasAcccessInCnpj, 'text');
				}
			});
		}

		return accessesResponse;
	}

	SetAccessInCurrentUser() {
		var currentUser: UserDocument = this.manageUsersService.GetCurrentUser();

		currentUser.acessos = this.gridUserAccess.GetCheckedObject();

		this.manageUsersService.SetCurrentUser(currentUser);
	}

}
